// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-careweb-afspraak-reminders-js": () => import("./../../../src/pages/careweb/afspraak-reminders.js" /* webpackChunkName: "component---src-pages-careweb-afspraak-reminders-js" */),
  "component---src-pages-careweb-data-importeren-js": () => import("./../../../src/pages/careweb/data-importeren.js" /* webpackChunkName: "component---src-pages-careweb-data-importeren-js" */),
  "component---src-pages-careweb-dossier-compleet-js": () => import("./../../../src/pages/careweb/dossier-compleet.js" /* webpackChunkName: "component---src-pages-careweb-dossier-compleet-js" */),
  "component---src-pages-careweb-js": () => import("./../../../src/pages/careweb.js" /* webpackChunkName: "component---src-pages-careweb-js" */),
  "component---src-pages-careweb-website-koppeling-aanmelding-js": () => import("./../../../src/pages/careweb/website-koppeling-aanmelding.js" /* webpackChunkName: "component---src-pages-careweb-website-koppeling-aanmelding-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-demo-website-koppeling-aanmelding-js": () => import("./../../../src/pages/demo/website-koppeling-aanmelding.js" /* webpackChunkName: "component---src-pages-demo-website-koppeling-aanmelding-js" */),
  "component---src-pages-demo-website-koppeling-intake-js": () => import("./../../../src/pages/demo/website-koppeling-intake.js" /* webpackChunkName: "component---src-pages-demo-website-koppeling-intake-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-over-meneer-jansen-js": () => import("./../../../src/pages/over-meneer-jansen.js" /* webpackChunkName: "component---src-pages-over-meneer-jansen-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */)
}

